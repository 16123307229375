<template>
	<div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
				<DataTable :value="$store.state.risk.detail" :scrollable="true" :scrollHeight="heigt_sc+'px'"  v-model:selection="selectedCustomers" 
					:filters="filters" class="p-datatable-sm" :paginator="false" editMode="cell" dataKey="id"  
					@cell-edit-init="Init" @cell-edit-complete="Save" v-model:editingRows="editingRows" v-model:expandedRows="expandedRows">
					<template #header>
						<div class="table-header">
							<div>
								<strong>{{$t('Responsible')}}:</strong>
								{{ $store.state.risk.data.responsible }}
							</div>
						</div>
					</template>
					<Column :header="$t('N°')" bodyStyle="width: 30px; background-color: #CECECE; text-align: center;" headerStyle="width: 30px; background-color: #CECECE; text-align: center;">
						<template #body="slotProps" >
							{{slotProps.index+1}}
						</template>
						<template #editor="slotProps">
							{{slotProps.index+1}}
						</template>
					</Column>
					<Column field="status" :header="$t('Status')" headerStyle="width: 130px">
						<template #body="{data}">
							{{ data['status_'+i18n.locale()] }}<i class="pi pi-pencil edit_tab" v-if="data.status_id==3"/>
						</template>
						<template #editor="{ data }">
							<div v-if="data.status_id==3">
								<div v-for="opc in $store.state.risk.basic.status.filter(val => val.id==2 || val.id==3 || val.id==4)" :key="opc.key" class="p-ml-1 p-mr-1 p-mb-1">
									<RadioButton v-model="data.status" :inputId="opc.key" name="dynamic" :value="opc"/>
									<label :for="opc.key" class="p-ml-2 p-mr-2">{{ opc[i18n.locale()] }}</label>
								</div>
							</div>
							<div v-else>
								{{ data['status_'+i18n.locale()] }}
							</div>
						</template>
					</Column>
					<Column :expander="true" headerStyle="width: 30px" />
                    <Column field="description" :header="$t('Scenario')" headerStyle="width: 300px">
						<template #body="{data}">
							{{ data.description }}
						</template>
						<template #editor="{ data }">
							{{ data.description }}
						</template>
					</Column>
                    <Column field="mitigating_measures" :header="$t('Which mitigating measures are in place for each risk scenario?')" headerStyle="width: 300px">
                        <template #body="{data}">
                            {{ data.mitigating_measures }}<i class="pi pi-pencil edit_tab" v-if="data.status_id==3"/>
                        </template>
                        <template #editor="{ data }">
							<div v-if="data.status_id==3">
								<Textarea v-model="data.mitigating_measures" autoResize rows="3" style="width: 100%;"/>
							</div>
							<div v-else>
								{{ data.mitigating_measures }}
							</div>
                        </template>
                    </Column>
					<Column field="responsible" :header="$t('Responsible')" headerStyle="width: 150px">
						<template #body="{data}">
							{{ data.responsible }}<i class="pi pi-pencil edit_tab" v-if="data.status_id==3"/>
						</template>
						<template #editor="{ data }">
							<div v-if="data.status_id==3">
								<InputText v-model="data.responsible" style="width: 100%;"/>
							</div>
							<div v-else>
								{{ data.responsible }}
							</div>
						</template>
					</Column>
					<Column field="date_start" :header="$t('Date')+' '+$t('start')+' - '+$t('end')" headerStyle="width: 250px">
						<template #body="{data}">
							{{ data['date_start_'+i18n.locale()] }} - {{ data['date_end_'+i18n.locale()] }}<i class="pi pi-pencil edit_tab" v-if="data.status_id==3"/>
						</template>
						<template #editor="{ data }">
							<div v-if="data.status_id==3">
								<InputText v-model="data.date_start" type="date" style="width: 50%;"/>
								<InputText v-model="data.date_end" type="date" style="width: 50%;"/>
							</div>
							<div v-else>
								{{ data['date_start_'+i18n.locale()] }} - {{ data['date_end_'+i18n.locale()] }}
							</div>
						</template>
					</Column>
                    <Column field="issues" :header="$t('Did audit/compliance have any findings; haveThere been any incidents')" headerStyle="width: 300px">
                        <template #body="{data}">
                            {{ data.issues }}<i class="pi pi-pencil edit_tab" v-if="data.status_id==3"/>
                        </template>
                        <template #editor="{ data }">
							<div v-if="data.status_id==3">
								<Textarea v-model="data.issues" autoResize rows="3" style="width: 100%;"/>
							</div>
							<div v-else>
								{{ data.issues }}
							</div>
                        </template>
                    </Column>
					<template #expansion="{data}">
						<Basic :data="data" :step="2"/>
					</template>	
				</DataTable>
        </div>
	</div>
</template>

<script>
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import Token from "uuid-token-generator";
	import { defineAsyncComponent } from "@vue/runtime-core";

	export default {
		components: {
			Basic: defineAsyncComponent(() => import('./Basic.vue')),
        },
		props: {
			step: {
				type: Number,
				default: 0,
			},
		},
		data() {
			return {
				i18n: null,
				url: null,
				img: null,
				porc: 0,
				heigt_sc: 0,
				editingRows: [],
				expandedRows: [],
				product: null,
				error:null,
			}
		},
		productService: null,
		created() {
			this.i18n = useI18nPlugin();
			this.url = process.env.VUE_APP_API;
			const tokgen = new Token(256);
			this.img = tokgen.generate();
			if(this.$store.state.screen<=728){
				this.heigt_sc =  380;
			} else {
				this.heigt_sc =  500;
			}
		},
		mounted() {
			
		},
		methods: {
			Init(event) {
				let { data, index, field } = event;
				this.$store.state.risk.editing.push(field+index);
				switch (field) {
					case 'status':
						this.$store.state.risk.detail[index][field] = this.$store.state.risk.basic.status.filter(val => val.id == data.status)[0];
						break;
					case 'likelihood':
						this.$store.state.risk.detail[index][field] = this.likelihood.filter(val => val.id == data.likelihood)[0];
						break;
				}
			},
			Save(event) {
				let { data, index, field } = event;
				this.$store.state.risk.editing = this.$store.state.risk.editing.filter(val => val != field+index);
				switch (field) {
					case 'status':
						this.$store.state.risk.detail[index].status_en = data.status.en;
						this.$store.state.risk.detail[index].status_es = data.status.es;
						this.$store.state.risk.detail[index].status = data.status.id;
						break;
					case 'date_start':
						this.$store.state.risk.detail[index].date_start_en = this.ConvDate(data.date_start);
						this.$store.state.risk.detail[index].date_start_es = this.ConvDate(data.date_start);
						break;
					case 'date_end':
						this.$store.state.risk.detail[index].date_end_en = this.ConvDate(data.date_end);
						this.$store.state.risk.detail[index].date_end_es = this.ConvDate(data.date_end);
						break;
				}
			},
            ConvDate(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},

		}
	}
</script>
<style scoped lang="scss">
.cheap {
	background-color: #54a90a !important;
	color: #ffffff !important;
}

.edit_tab{
	padding: 10px 10px 10px 10px;
	font-size: 10px;
	color: red;
}

.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
    padding: 1em 0em 1em 0.5em;
}
.text-title{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

@media (max-width: 640px) {

	
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}


				}
			}
		}
	}
}
</style>